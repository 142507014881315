import type {ActionFunctionArgs} from '@remix-run/node'
import {redirect} from '@remix-run/node'
import {withZod} from '@rvf/zod'
import {z} from 'zod'
import {zfd} from 'zod-form-data'
import {parseFormData} from '~/utils/forms.server'
import {plain, plainLabels, upsertPlainCustomer} from '~/utils/plain.server'
import {getSession} from '~/utils/session.server'

export const validator = withZod(
  z.object({
    kind: z.enum(['bug', 'feedback', 'question'], {
      invalid_type_error: 'Kind must be one of: bug, feedback, question',
    }),
    message: z.string({required_error: 'Message is required'}).min(1, 'Message is required'),
    urgent: z.optional(zfd.checkbox()),
  }),
)

export async function loader() {
  return redirect('/orgs')
}

export async function action({request}: ActionFunctionArgs) {
  const formData = await request.formData()
  const session = await getSession(request)
  const user = await session.requireUser()
  const {data} = await parseFormData(validator, formData)

  await upsertPlainCustomer(user)

  switch (data.kind) {
    case 'bug': {
      await plain.createThread({
        title: 'Bug report',
        customerIdentifier: {externalId: user.id},
        components: [{componentText: {text: data.message}}],
        labelTypeIds: [plainLabels.bugReport],
        priority: data.urgent ? 0 : 2,
      })
      break
    }

    case 'feedback': {
      await plain.createThread({
        title: 'Feedback form',
        customerIdentifier: {externalId: user.id},
        components: [{componentText: {text: data.message}}],
        labelTypeIds: [plainLabels.featureRequest],
      })
      break
    }

    case 'question': {
      await plain.createThread({
        title: 'Question form',
        customerIdentifier: {externalId: user.id},
        components: [{componentText: {text: data.message}}],
        labelTypeIds: [plainLabels.generalQuestion],
      })
      break
    }
  }

  return {ok: true}
}
